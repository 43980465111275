import { Box, SystemProps, Flex } from "@storyofams/react-ui";
import Image from "next/image";

import { getLinkProps } from "~lib";

import { Button } from "../../Button";
import { Text } from "../../Text";

type SmallBannerProps = {
  first?: boolean;
  content: {
    button_text?: string;
    button_link: any;
    title?: string;
    description: string;
    image?: {
      filename: string;
    };
  };
} & SystemProps;

export const SmallBanner = ({ content, first, ...props }: SmallBannerProps) => {
  return (
    <Box
      backgroundColor={"#F4F3F0"}
      borderRadius={"8px"}
      display={"flex"}
      width={"100%"}
      p={["20px", "32px"]}
      alignItems={["start", "center"]}
      flexDirection={["column", "row"]}
    >
      <Flex
        width={"100%"}
        alignItems={["start", "center"]}
        flexDirection={["column", "row"]}
      >
        {content.image.filename && (
          <Box
            width={"132.25px"}
            height={"132.25px"}
            position={"relative"}
            borderRadius={"full"}
            flexShrink={0}
            overflow={"hidden"}
          >
            <Image
              objectFit="cover"
              layout="fill"
              src={content.image.filename}
            />
          </Box>
        )}

        <Box
          maxWidth={"776px"}
          ml={[0, content?.image?.filename && 4]}
          mt={[content?.image?.filename && 3, 0]}
        >
          <Text
            fontWeight={700}
            fontFamily={"DINPro"}
            fontSize={
              content?.image?.filename ? ["23px", "36px"] : ["40px", "40px"]
            }
            lineHeight={
              content?.image?.filename
                ? ["28.8px", "43.2px"]
                : ["43.2px", "48px"]
            }
            mb={content?.image?.filename && 2}
          >
            {content.title}
          </Text>
          <Text
            color={!content?.image?.filename && "#1D1D1B"}
            fontWeight={!content?.image?.filename && 700}
            fontFamily={!content?.image?.filename && "DINPro"}
            fontSize={
              content?.image?.filename ? ["16px", "18px"] : ["40px", "40px"]
            }
            lineHeight={
              content?.image?.filename
                ? ["22.4px", "25.2px"]
                : ["43.2px", "48px"]
            }
            mb={[3, 0]}
          >
            {content.description}
          </Text>
        </Box>
      </Flex>

      <Button
        href={content.button_link.url}
        fontSize={"18px"}
        height={"57px"}
        width={["100%", "max-content"]}
        whiteSpace={"nowrap"}
        variant="primary"
      >
        {content.button_text}
      </Button>
    </Box>
  );
};
